import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"items-list"}},[_c(VRow,{staticClass:"mb-5"},[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"3"}},[_c(VCard,[_c(VCardText,{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v("Choose the Date")]),_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":-20,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"mb-6 required-star",attrs:{"outlined":"","dense":"","label":"Date","placeholder":"YYYY-MM-DD","hide-details":"auto"},on:{"paste":function($event){$event.preventDefault();},"keypress":function($event){$event.preventDefault();}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}},[_c(VDatePicker,{attrs:{"max":_vm.now,"no-title":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.picker = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.picker = false}}},[_vm._v(" OK ")])],1)],1),_c(VBtn,{staticClass:"col-12",attrs:{"color":"primary"},on:{"click":_vm.fetchByDate}},[_c('span',[_vm._v("Confirm")])])],1)])],1)],1)],1),_c('div',{attrs:{"id":"sizes-list"}},[_c(VCard,[_c('div',{staticClass:"title"},[_c('h3',{staticClass:"font-weight-semibold mb-1 py-10 px-10"},[_vm._v("History")]),_c(VBtn,{staticClass:"px-10 py-6 mb-4 mr-10",attrs:{"color":"secondary","outlined":""},on:{"click":_vm.downLoadxlsx}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"27"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1),_c(VDivider,{staticClass:"mt-4"}),_c(VDataTable,{attrs:{"id":"table1","headers":_vm.scheduledTableColumnsHestory,"items":_vm.dailyMealsListTable,"options":_vm.options,"server-items-length":_vm.totalDailyMealsListTable.length,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.rate + '★' ? item.rate : '___')+" ")])])]}},{key:"item.name_en",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.name_en)+" ")])])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.category ? item.category.name_en : '__')+" ")])])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.size ? item.size + ',' + item.price : '___')+" ")])])]}},{key:"item.is_default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.is_default ? item.is_default : '___')+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.quantity ? 'In Stok' : 'Out Stok')+" ")])])]}},{key:"item.kitchen_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.kitchen_name ? item.kitchen_name : '___')+" ")])])]}},{key:"item.name_ar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.name_ar))])])]}},{key:"item.chief_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.chief_name ? item.chief_name : '___'))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.saveDate(item.item_id)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]),_c('span',[_vm._v("View")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.scheduledSelectedRows),callback:function ($$v) {_vm.scheduledSelectedRows=$$v},expression:"scheduledSelectedRows"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }