<template>
  <div id="items-list">
    <v-row class="mb-5">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">Choose the Date</h2>
              <v-menu
                v-model="picker"
                :close-on-content-click="false"
                :nudge-right="-20"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedDate"
                    outlined
                    dense
                    @paste.prevent
                    @keypress.prevent
                    label="Date"
                    placeholder="YYYY-MM-DD"
                    hide-details="auto"
                    class="mb-6 required-star"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker :max="now" v-model="selectedDate" no-title>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="picker = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="picker = false"> OK </v-btn></v-date-picker
                >
              </v-menu>
              <v-btn color="primary" class="col-12" @click="fetchByDate">
                <span>Confirm</span>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div id="sizes-list">
      <v-card>
        <div class="title">
          <h3 class="font-weight-semibold mb-1 py-10 px-10">History</h3>

          <v-btn color="secondary" outlined class="px-10 py-6 mb-4 mr-10" @click="downLoadxlsx">
            <v-icon size="27" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
        <v-divider class="mt-4"></v-divider>

        <v-data-table
          id="table1"
          v-model="scheduledSelectedRows"
          :headers="scheduledTableColumnsHestory"
          :items="dailyMealsListTable"
          :options.sync="options"
          :server-items-length="totalDailyMealsListTable.length"
          :loading="loading"
        >
          <!-- rate -->
          <template #[`item.rate`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.rate + '★' ? item.rate : '___' }}
              </div>
            </div>
          </template>

          <!-- name EN -->
          <template #[`item.name_en`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.name_en }}
              </div>
            </div>
          </template>
          <template #[`item.category`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.category ? item.category.name_en : '__' }}
              </div>
            </div>
          </template>

          <template #[`item.size`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.size ? item.size + ',' + item.price : '___' }}
              </div>
            </div>
          </template>
          <template #[`item.is_default`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.is_default ? item.is_default : '___' }}
              </div>
            </div>
          </template>
          <template #[`item.status`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.quantity ? 'In Stok' : 'Out Stok' }}
              </div>
            </div>
          </template>
          <template #[`item.kitchen_name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                {{ item.kitchen_name ? item.kitchen_name : '___' }}
              </div>
            </div>
          </template>

          <!-- name AR -->
          <template #[`item.name_ar`]="{ item }">
            <div class="d-flex align-center">
              <span class="text-capitalize">{{ item.name_ar }}</span>
            </div>
          </template>
          <template #[`item.chief_name`]="{ item }">
            <div class="d-flex align-center">
              <span class="text-capitalize">{{ item.chief_name ? item.chief_name : '___' }}</span>
            </div>
          </template>
          <!-- actions -->
          <template #[`item.actions`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="saveDate(item.item_id)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiEye }}
                    </v-icon>
                    <span>View</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <!-- list filters -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { mdiDotsVertical, mdiFileDocumentOutline } from '@mdi/js'
import { onUnmounted, ref, onMounted } from '@vue/composition-api'
import moment from 'moment'
import { export_table_to_excel } from 'anas-export-excel'

// sidebar
import Swal from 'sweetalert2'
import inventoryStoreModule from './../inventoryStoreModule'
import useDailyMealsList from '../inventory-list/useDailyMealsList'
import useItemsList from '../inventory-list/useItemsList'
import router from '@/router'
// sidebar

export default {
  setup() {
    const INVENTORY_APP_STORE_MODULE_NAME = 'app-inventory'

    // Register module
    if (!store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVENTORY_APP_STORE_MODULE_NAME, inventoryStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
      if (localStorage.getItem('inventoryHDate')?.length) {
        selectedDate.value = localStorage.getItem('inventoryHDate')
        fetchByDate()
        localStorage.removeItem('inventoryHDate')
      }
    })
    const picker = ref(false)
    const now = ref(moment().add(-1, 'days').format())
    const dailyMealItem = ref(null)
    const sub_category = ref([])
    const editItem = ref({})

    const {
      itemsListTable,
      tableColumns,
      searchQuery,
      totalItemsListTable,
      loading,
      options,
      itemsTotalLocal,
      selectedRows,
      planFilter,
      subcategory_id,
      selectedDate,
      fetchItems,
    } = useItemsList()

    const {
      dailyMealsListTable,
      scheduledTableColumnsHestory,
      totalDailyMealsListTable,
      sizesTotalLocal,
      scheduledSelectedRows,
      fetchDailyMealsHistory,
    } = useDailyMealsList()

    const planOptions = [
      { title: 'Name (EN)', value: 'name_en' },
      { title: 'Name (AR)', value: 'name_ar' },
      { title: 'Chef Name', value: 'Test' },
      { title: 'Meals', value: 'Test1' },
      { title: 'Pre Order', value: 'Test2' },
    ]

    const fetchByDate = () => {
      if (selectedDate.value.length > 0) {
        fetchDailyMealsHistory(selectedDate.value)
        fetchItems(selectedDate.value)
      } else {
        Swal.fire('Please Choose a date first to fetch the available items!')
      }
    }
    const saveDate = (id) => {
      if (selectedDate.value.length > 0) {
        localStorage.setItem('inventoryHDate', selectedDate.value)
      }
      router.push({
        name: 'apps-inventory-view',
        params: { id: id },
        query: { date: selectedDate ? selectedDate : '' },
      })
    }

    store
      .dispatch('app-inventory/fetchSubCategories')
      .then(response => {
        sub_category.value = response.filteredData
        console.log('sub_category.value', sub_category.value)
      })
      .catch(error => {
        console.error(error)
      })

    const downLoadxlsx = () => {
      export_table_to_excel('table1', tableColumns, 'InventoryHistoryList')
    }

    return {
      saveDate,
      downLoadxlsx,
      subcategory_id,
      sub_category,
      itemsListTable,
      tableColumns,
      searchQuery,
      totalItemsListTable,
      loading,
      options,
      now,
      itemsTotalLocal,
      selectedRows,
      planOptions,
      planFilter,
      dailyMealsListTable,
      scheduledTableColumnsHestory,
      totalDailyMealsListTable,
      sizesTotalLocal,
      scheduledSelectedRows,
      selectedDate,
      picker,
      editItem,
      dailyMealItem,
      fetchDailyMealsHistory,
      fetchItems,
      fetchByDate,

      // icons
      icons: {
        mdiFileDocumentOutline,
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.title {
  display: flex !important;
  justify-content: space-between !important;
  align-items: baseline !important;
}
</style>
